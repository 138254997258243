<template>
  <div class="data-tools">
    <div class="data-tools__header">
      <molecule-navigation
        :items="navigationItems"
        class="data-tools__navigation"
      ></molecule-navigation>
    </div>
    <div class="data-tools__sections">
      <div v-for="(documents, institutionName) in documentsByInstitutionName" :key="institutionName" class="data-tools__section">
        <div class="data-tools__section-header">
          <h2 class="data-tools__section-heading">{{institutionName}}</h2>
          <div class="data-tools__section-line"></div>
        </div>
        <div v-if="documents.length" class="data-tools__slider">
          <Carousel v-if="documents.length" :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="(document, index) in documents" :key="index">
              <molecule-article-card
                class="data-tools__slider-item"
                :post="document"
              ></molecule-article-card>
            </Slide>
            <template #addons="{ slidesCount }">
              <Navigation v-if="slidesCount > 3" />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MoleculeNavigation from "@/components/molecules/MoleculeNavigation.vue";
import MoleculeArticleCard from "@/components/molecules/MoleculeArticleCard.vue";
import MoleculeArticlePreview from "@/components/molecules/MoleculeArticlePreview.vue";
import MoleculeArticleResource from "@/components/molecules/MoleculeArticleResource.vue";
import {useRoute} from 'vue-router';
import {useStore} from "vuex";
import {computed, onBeforeMount} from "vue";

const {state, dispatch} = useStore();

const documents = computed(() => state.documents.documents.data);
const documentsByInstitutionName = computed( () => {
  // Group documents by institution name
  const documentsByInstitutionName = {};
  documents.value.forEach((document) => {
    if (documentsByInstitutionName[document.institutionName]) {
      documentsByInstitutionName[document.institutionName].push(document);
    } else {
      documentsByInstitutionName[document.institutionName] = [document];
    }
  });
  return documentsByInstitutionName;
});
const route = useRoute();
const navigationItems = [
  {
    name: "Page d'accueil",
    link: { name: "Home" },
  },
  {
    name: "Digitech",
    link: { name: "DataAndTools" },
  },
];
// Data and tools content
// Slider settings
const settings = {
  itemsToShow: 1,
  wrapAround: true,
  snapAlign: "left",
};
const breakpoints = {
  1024: {
    itemsToShow: 2,
  },
  1280: {
    itemsToShow: 3,
  },
};
onBeforeMount(async () => {
  await dispatch('documents/getAll',{
    limit: 1000
  })
  console.log(documentsByInstitutionName.value);
})
</script>

<style lang="scss">
.data-tools {
  @apply container mx-auto px-4 pb-9;
  &__header {
    @apply flex flex-col flex-wrap items-start mt-2
    sm:flex-row sm:items-center sm:justify-between
    mb-9;
  }
  &__navigation {
  }
  &__search {
    @apply relative;
    &-input {
      @apply text-sm font-medium leading-5 rounded-md
        border border-[#D1D5DB] w-[318px] p-[10px] pr-[42px];
      &::placeholder {
        @apply text-black text-sm font-medium;
      }
    }
    &-icon {
      @apply w-4 h-4
        absolute right-2 top-1/2 -translate-y-1/2;
    }
  }
  &__sections{
    @apply flex flex-col gap-10;
  }
  &__section {
    &-header {
      @apply flex items-center gap-4 mb-4;
    }
    &-line {
      @apply h-[1px] bg-gray-100 flex-1;
    }
    &-heading {
      @apply text-lg font-medium
      flex items-center relative
      md:text-xl;
    }
  }
  &__slider {
    @apply mb-3 md:mb-0 order-2
    md:order-1;
    &-item {
      @apply text-left m-2;
    }
  }
  &__aside {
    @apply flex flex-col gap-4 order-1
    md:order-2;
  }
  &__body {
    @apply flex flex-col gap-9;
  }
  .carousel {
    &__icon {
      @apply fill-[gray] h-6 w-6;
    }
    &__prev,
    &__next {
      @apply bg-gray-100 h-8 w-8;
    }
    &__prev {
      @apply -left-4;
    }
    &__next {
      @apply -right-4;
    }
  }
}
</style>